import React, { useState } from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import moment from 'moment'

import { User } from '~types'
import { Table } from '~components'

type UsersTableProps = {
  users: User[]
}

export const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  const { t } = useTranslation()
  const [sorting, setSorting] = useState<SortingState>([])

  const columnHelper = createColumnHelper<User>()

  const columns = [
    columnHelper.accessor((row) => row.email, {
      id: 'email',
      cell: (info) => (
        <Link to={`/users/${info.row.original.id}/edit`}>
          <i>{info.getValue()}</i>
        </Link>
      ),
      header: () => t('Fields.email', 'Email'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('firstName', {
      header: () => t('Fields.firstName', 'First name'),
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('lastName', {
      header: () => t('Fields.lastName', 'Last name'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('userRole.role.name', {
      header: () => t('Fields.role', 'Role'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('isActive', {
      header: () => t('Fields.isActive', 'Is active'),
      footer: (info) => info.column.id,
      cell: (info) =>
        info.getValue() ? (
          <CheckCircleIcon className="w-4 h-4 text-green-700" />
        ) : (
          <XCircleIcon className="w-4 h-4 text-red-700" />
        ),
    }),
    columnHelper.accessor('dateJoined', {
      header: () => t('Fields.createdAt', 'Created'),
      footer: (info) => info.column.id,
      cell: (info) => moment(info.getValue()).format('DD-MM-YYYY HH:mm'),
    }),
  ]
  const table = useReactTable<User>({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  })

  return <Table table={table} key="users" />
}
