import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'

import { Button, InputField } from '~components'
import { CreateUserProps, EditUserProps, Role } from '~types'
import useApi from '~api/api'

type InitialValues = CreateUserProps | EditUserProps

type UserFormProps = {
  initialValues: InitialValues
  submitType: 'create' | 'edit'
  onSubmit: (values: InitialValues, actions: FormikHelpers<InitialValues>) => void
  loading?: boolean
}

export const UserForm: React.FC<UserFormProps> = ({
  initialValues,
  submitType,
  onSubmit,
  loading,
}: UserFormProps) => {
  const { t } = useTranslation()
  const api = useApi()

  const {
    data: roles,
    isLoading: isLoadingRoles,
    error: fetchRolesError,
  } = useQuery<Role[]>({
    queryKey: ['roles'],
    queryFn: async () => {
      const response = await api.get<Role[]>(`/perms/roles/`)
      return response.data
    },
  })

  if (!roles || isLoadingRoles) {
    return null
  }

  if (fetchRolesError) {
    return <div>Error: {fetchRolesError.message}</div>
  }

  const roleValues = roles.map((role) => ({
    id: role.id,
    name: role.name,
  }))

  const currentPerms = initialValues.userRole.role

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(150).required(t('ValidationMessages.required', 'Is required')),
        lastName: Yup.string().max(150).required(t('ValidationMessages.required', 'Is required')),
        userRole: Yup.object().shape({
          role: Yup.object().shape({
            id: Yup.number().required(t('ValidationMessages.required', 'Is required')),
          }),
        }),
        isActive: Yup.boolean(),
        email: Yup.string()
          .max(200)
          .email()
          .required(t('ValidationMessages.required', 'Is required')),
      })}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ values, dirty, setFieldValue }) => {
        const isRoleChanged = currentPerms.id !== values.userRole.role.id
        const newRole = values.userRole.role
        return (
          <Form className="flex flex-col">
            <InputField
              name="isActive"
              type="switch"
              label={
                values.isActive ? t('Fields.active', 'Active') : t('Fields.inactive', 'Inactive')
              }
              className="pb-2 self-end text-lg"
              invertedLabel
            />
            <InputField
              name="email"
              disabled={submitType === 'edit'}
              type="text"
              label={t('Fields.email', 'Email')}
              className="pb-2"
            />
            <InputField
              name="firstName"
              type="text"
              label={t('Fields.firstName', 'First Name')}
              className="pb-2"
            />
            <InputField
              name="lastName"
              type="text"
              label={t('Fields.lastName', 'Last Name')}
              className="pb-2"
            />
            {roles && !isLoadingRoles && (
              <InputField
                name="userRole.role.id"
                type="select"
                label={t('Fields.role', 'Role')}
                selectValues={roleValues}
                onChange={(e) => {
                  console.log('e.target.value', e.target.value)
                  const role = roles.find((role) => role.id === parseInt(e.target.value))
                  if (role) {
                    setFieldValue('userRole.role', role)
                  }
                }}
                className="pb-2"
              />
            )}
            <Button
              type="submit"
              className="py-2 font-semibold text-lg"
              loading={loading}
              disabled={!dirty}>
              {submitType === 'edit'
                ? t('General.update', 'Update')
                : t('General.create', 'Create')}{' '}
              {t('General.user', 'User')}
            </Button>
            <div className="mt-4">
              <div className={clsx(['grid', isRoleChanged ? 'grid-cols-3' : 'grid-cols-2'])}>
                <div className="bg-teal-400 text-base font-bold p-2">
                  {t('General.permission', 'Permission')}
                </div>
                <div className="bg-teal-400 text-base font-bold p-2">
                  {t('General.current', 'Current')}
                </div>
                {isRoleChanged && (
                  <div className="bg-teal-400 text-base font-bold p-2">
                    {t('General.selected', 'Selected')}
                  </div>
                )}
                <div className="font-bold">{t('Fields.name', 'Name')}</div>
                <div>{currentPerms.name}</div>
                {isRoleChanged && <div>{newRole.name}</div>}
                <div className="font-bold bg-teal-50">{t('Fields.description', 'Description')}</div>
                <div className="bg-teal-50">{currentPerms.description}</div>
                {isRoleChanged && <div className="bg-teal-50">{newRole.description}</div>}
                <div className="font-bold">{t('Fields.canRefund', 'Can Issue Refund')}</div>
                <div>{currentPerms.canRefund ? t('General.yes') : t('General.no')}</div>
                {isRoleChanged && (
                  <div>{newRole.canRefund ? t('General.yes') : t('General.no')}</div>
                )}
                {/* <div className="font-bold bg-teal-50">
                  {t('Fields.refundTimeout', 'Refund Expires (seconds)')}
                </div>
                <div className="bg-teal-50">{currentPerms.refundTimeout}</div>
                {isRoleChanged && <div className="bg-teal-50">{newRole.refundTimeout}</div>} */}
                <div className="font-bold">{t('Fields.canReprint', 'Can Reprint Tickets')}</div>
                <div>{currentPerms.canReprint ? t('General.yes') : t('General.no')}</div>
                {isRoleChanged && (
                  <div>{newRole.canReprint ? t('General.yes') : t('General.no')}</div>
                )}
                {/* <div className="font-bold bg-teal-50">
                  {t('Fields.reprintTimeout', 'Reprinf Timeout (seconds)')}
                </div>
                <div className="bg-teal-50">{currentPerms.reprintTimeout}</div>
                {isRoleChanged && <div className="bg-teal-50">{newRole.reprintTimeout}</div>} */}
                <div className="font-bold">
                  {t('Fields.canViewAny', 'Can View Any Transaction')}
                </div>
                <div>{currentPerms.canViewAny ? t('General.yes') : t('General.no')}</div>
                {isRoleChanged && (
                  <div>{newRole.canViewAny ? t('General.yes') : t('General.no')}</div>
                )}
                <div className="font-bold bg-teal-50">
                  {t('Fields.canRefundAny', 'Can Refund Any Transaction')}
                </div>
                <div className="bg-teal-50">
                  {currentPerms.canRefundAny ? t('General.yes') : t('General.no')}
                </div>
                {isRoleChanged && (
                  <div className="bg-teal-50">
                    {newRole.canRefundAny ? t('General.yes') : t('General.no')}
                  </div>
                )}
                <div className="font-bold">
                  {t('Fields.canReprintAny', 'Can Reprint Any Transaction')}
                </div>
                <div>{currentPerms.canReprintAny ? t('General.yes') : t('General.no')}</div>
                {isRoleChanged && (
                  <div>{newRole.canReprintAny ? t('General.yes') : t('General.no')}</div>
                )}
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
