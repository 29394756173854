import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral'

import useApi from '~api/api'
import { Spinner } from '~components'
import {
  Category,
  CreateTicketTypeProps,
  Currency,
  Tenant,
  TicketType,
  UpdateTicketTypeProps,
} from '~types'

interface VisualizerProps {
  ticket: CreateTicketTypeProps | UpdateTicketTypeProps
}

export const TicketVisualizer: React.FC<VisualizerProps> = ({ ticket }) => {
  const [currentTab, setCurrentTab] = useState('55mm')
  const api = useApi()
  const { t } = useTranslation()
  const transactionId = `123456789-${moment().unix()}`

  const currencyFormat = '0,0.00'
  const percentFormat = '0,0.00%'

  const vatRate = parseFloat(ticket.vatRate) / 100

  const isWideScreen = currentTab === '80mm'
  const charsPerLine = isWideScreen ? 50 : 40
  //   const charsDwPerLine = isWideScreen ? 24 : 18 // Adjusted for double width

  const multilineSizing = (text: string, maxChars?: number) => {
    if (!maxChars) {
      maxChars = charsPerLine
    }
    if (text.length < maxChars) {
      return text
    }
    const patternString = `.{1,${maxChars}}(?:\\s|$)`
    const pattern = new RegExp(patternString, 'g')
    const chunks = text.match(pattern)
    const trimmedChunks = chunks ? chunks.map((chunk) => chunk.trim()) : []
    return trimmedChunks.join('\n')
  }

  //   const variableSizing = (text: string) => {
  //     if (text.length > charsDwPerLine) {
  //       return formatOptions.doubleHeight
  //     }
  //     return formatOptions.doubleSize
  //   }

  const currencies = useQuery<Currency[]>({
    queryKey: ['currencies'],
    queryFn: async () => {
      const response = await api.get<Currency[]>('/tenants/currencies/')
      return response.data
    },
  })

  const tenant = useQuery<Tenant>({
    queryKey: ['tenant'],
    queryFn: async () => {
      const response = await api.get<Tenant>('/tenants/')
      return response.data
    },
  })

  const category = useQuery<Category>({
    queryKey: ['categories', ticket.category],
    queryFn: async () => {
      const response = await api.get<Category>(`/tickets/categories/${ticket.category}/`)
      return response.data
    },
  })

  if (!ticket || !currencies.data || !tenant.data || !category.data) {
    return null
  }

  if (currencies.isLoading || tenant.isLoading || category.isLoading) {
    return <Spinner size="4x" />
  }

  const Separator = () => (
    <div>
      {currentTab === '80mm'
        ? '--------------------------------------------------'
        : '---------------------------------------'}
    </div>
  )

  const bundled = (bundled: TicketType | CreateTicketTypeProps | UpdateTicketTypeProps) => (
    <>
      <div className="text-3xl text-center py-2">{tenant.data.name}</div>
      <div>
        {t('PrintedTicket.transaction_id')}: {transactionId}
      </div>
      <div>
        {t('PrintedTicket.sold_on')}: {moment().utc().local().format('DD-MM-YYYY HH:mm')}
      </div>
      <div
        className="text-3xl align-center text-center py-2"
        dangerouslySetInnerHTML={{ __html: bundled.name.replace('\\n', '<br/>') }}></div>
      <div className="align-center py-8 text-center">-------------- cut --------------</div>
    </>
  )

  const TicketDisplay = () => (
    <div>
      <div>
        {t('PrintedTicket.transaction_id')}: {transactionId}
      </div>
      <div>{t('PrintedTicket.sold_by')}: 123</div>
      <Separator />
      <div className="text-3xl">{moment().utc().local().format('DD-MM-YYYY HH:mm')}</div>
      <div
        className="text-3xl"
        dangerouslySetInnerHTML={{ __html: category.data.name.replace('\\n', '<br/>') }}></div>
      <div
        className="text-3xl"
        dangerouslySetInnerHTML={{ __html: ticket.name.replace('\\n', '<br/>') }}></div>
      <Separator />
      <div className="grid grid-cols-2  justify-between">
        <div>{t('PrintedTicket.price_of_ticket')}:</div>
        <div className="text-right">
          {tenant.data.currency.symbol} {numeral(ticket.pricePer).format(currencyFormat)}
        </div>
      </div>
      <div className="grid grid-cols-2 justify-between">
        <div>
          {t('PrintedTicket.vat_rate_ticket', {
            vat: numeral(vatRate).format(percentFormat),
          })}
          :
        </div>
        <div className="text-right">
          {tenant.data.currency.symbol} {numeral(ticket.pricePer * vatRate).format(currencyFormat)}
        </div>
      </div>
      <Separator />
      <div className="align-center text-center">
        {tenant.data.ticketEndMessage && tenant.data.ticketEndMessage.length > 0
          ? multilineSizing(tenant.data.ticketEndMessage)
          : t('PrintedTicket.thank_you_message')}
      </div>
      <div className="align-center py-8 text-center">-------------- cut --------------</div>
    </div>
  )

  return (
    <div className="flex flex-col items-center justify-center overflow-auto">
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav aria-label="Tabs" className="-mb-px flex space-x-8">
            <a
              key={'55mm'}
              onClick={() => setCurrentTab('55mm')}
              aria-current={currentTab === '55mm' ? 'page' : undefined}
              className={clsx(
                currentTab === '55mm'
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
              )}>
              55mm
            </a>
            <a
              key={'80mm'}
              onClick={() => setCurrentTab('80mm')}
              aria-current={currentTab === '80mm' ? 'page' : undefined}
              className={clsx(
                currentTab === '80mm'
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
              )}>
              80mm
            </a>
          </nav>
          {ticket.isBundledOnly ? (
            <div
              className={`font-sans border border-gray-300 p-4 mt-4 shadow-lg bg-white ${
                isWideScreen ? 'w-mm80' : 'w-mm55'
              }`}>
              {bundled(ticket)}
            </div>
          ) : (
            <div
              className={`font-sans border border-gray-300 p-4 mt-4 shadow-lg bg-white ${
                isWideScreen ? 'w-mm80' : 'w-mm55'
              }`}>
              {tenant.data.ticketLogoBase64 && (
                <div className="flex flex-col items-center justify-center">
                  <img src={`data:image/bmp;base64,${tenant.data.ticketLogoBase64}`} alt="Logo" />
                </div>
              )}
              <TicketDisplay />
              {ticket.bundledTickets.length > 0 &&
                ticket.bundledTickets.map((bundle) => (
                  <div key={bundle.uid}>{bundled(bundle)}</div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
