import { useMutation, useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import useApi from '~api/api'
import { HeaderLayout, Spinner } from '~components'
import { Currency, Tenant } from '~types'

export const SettingsPage = () => {
  const { t } = useTranslation()
  const api = useApi()

  const currencies = useQuery<Currency[]>({
    queryKey: ['currencies'],
    queryFn: async () => {
      const response = await api.get<Currency[]>('/tenants/currencies/')
      return response.data
    },
  })

  const tenant = useQuery<Tenant>({
    queryKey: ['tenant'],
    queryFn: async () => {
      const response = await api.get<Tenant>('/tenants/')
      return response.data
    },
  })

  const tenantUpdate = useMutation({
    mutationFn: async (payload: { ticketEndMessage: string }) => {
      const response = await api.put<Tenant>(`/tenants/`, payload)
      return response.data
    },
    onSuccess: () => {
      tenant.refetch()
    },
  })

  const multilineSizing = (text: string, maxChars: number): string => {
    if (!text) {
      return ''
    }
    if (text.length <= maxChars) {
      return text
    }
    const patternString = `.{1,${maxChars}}(?:\\s|$)`
    const pattern = new RegExp(patternString, 'g')
    const chunks = text.match(pattern)
    const trimmedChunks = chunks ? chunks.map((chunk) => chunk.trim()) : ''
    return trimmedChunks && trimmedChunks.join('<br/>')
  }

  return (
    <HeaderLayout>
      <div className="relative h-full">
        <div className="flex flex-col p-8 h-full">
          {(tenant.isLoading || currencies.isLoading) && (
            <div className="flex justify-center items-center h-full">
              <Spinner size="7x" color="#75C0B7" />
            </div>
          )}
          {(tenant.isError || currencies.isError) && (
            <div>Error: {tenant.error?.message || currencies.error?.message}</div>
          )}
          {tenant.isSuccess && currencies.isSuccess && (
            <div>
              <h1 className="text-2xl font-bold mb-4">{tenant.data.name}</h1>
              <h2 className="text-xl font-bold mb-2">{t('General.locale', 'Locale')}</h2>
              <p>{tenant.data.locale}</p>
              <h2 className="text-xl font-bold mb-2">{t('General.currency', 'Currency')}</h2>
              <p>
                {tenant.data.currency.id} ({tenant.data.currency.symbol})
              </p>
              <h2 className="text-xl font-bold mb-2">
                {t('SettingsPage.ticket_logo', 'Ticket Logo')}
              </h2>
              <img
                src={`data:image/bmp;base64,${tenant.data.ticketLogoBase64}`}
                alt="Tenant Ticket Logo"
                className="mb-4"
              />
              <Formik
                initialValues={{ ticketEndMessage: tenant.data.ticketEndMessage || '' }}
                onSubmit={async (values) => {
                  await tenantUpdate.mutateAsync({ ticketEndMessage: values.ticketEndMessage })
                }}>
                {({ handleChange, handleSubmit, values }) => (
                  <form onSubmit={handleSubmit} className="mb-4">
                    <h2 className="text-xl font-bold mb-2">
                      {t('SettingsPage.ticket_end_message', 'Ticket End Message')}
                    </h2>
                    <div className="mb-4 grid grid-cols-3 gap-4">
                      <div>
                        <textarea
                          name="ticketEndMessage"
                          value={values.ticketEndMessage}
                          onChange={handleChange}
                          className="w-full h-32 border border-gray-300 rounded p-2"
                        />
                        <span className="pl-4 block text-sm text-teal-600">
                          {t(
                            'FieldDescs.auto_line_breaks_desc',
                            'Line breaks are automated on ticket to match ticket width.',
                          )}
                        </span>
                      </div>
                      <div>
                        <h3 className="text-lg font-bold mb-2 text-center">55mm</h3>
                        <div
                          className="font-sans text-sm text-center"
                          dangerouslySetInnerHTML={{
                            __html: multilineSizing(values.ticketEndMessage, 40),
                          }}></div>
                      </div>
                      <div>
                        <h3 className="text-lg font-bold mb-2 text-center">80mm</h3>
                        <div
                          className="font-sans text-sm text-center"
                          dangerouslySetInnerHTML={{
                            __html: multilineSizing(values.ticketEndMessage, 50),
                          }}></div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className={clsx(
                        'mt-4 bg-blue-500 text-white py-2 px-4 w-48 rounded',
                        tenantUpdate.isPending && 'opacity-50 cursor-not-allowed',
                      )}
                      disabled={tenantUpdate.isPending}>
                      {tenantUpdate.isPending ? <Spinner /> : t('General.update', 'Update')}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </HeaderLayout>
  )
}
