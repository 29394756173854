import { Link, Outlet } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Button, HeaderLayout, RolesTable, TableDisplay } from '~components'
import { Role } from '~types'
import useApi from '~api/api'

export const RolesListPage = () => {
  const { t } = useTranslation()
  const api = useApi()
  const {
    data: roles,
    isLoading: isLoadingRoles,
    error: rolesError,
  } = useQuery<Role[]>({
    queryKey: ['roles'],
    queryFn: async () => {
      const response = await api.get<Role[]>('/perms/roles/')
      return response.data
    },
  })

  return (
    <HeaderLayout>
      <div className="relative h-full">
        <div className="flex flex-col p-8 h-full">
          <div>
            <Link
              to="/roles/add"
              className="inline-flex items-center text-sm font-medium text-gray-900 py-4">
              <Button>{t('ListRolesScreen.newRole', 'New Role')}</Button>
            </Link>
          </div>
          <TableDisplay items={roles || []} isLoading={isLoadingRoles} error={rolesError}>
            <RolesTable roles={roles || []} />
          </TableDisplay>
        </div>
        <div className="fixed right-0 top-0 h-full">
          <Outlet />
        </div>
      </div>
    </HeaderLayout>
  )
}
