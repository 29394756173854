import React from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { PanelHeader, RightPanel, RoleForm } from '~components'
import { Role } from '~types'
import useApi from '~api/api'

export const RoleAddPage = () => {
  const api = useApi()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { mutateAsync: addRole, isPending: loadingAddUser } = useMutation({
    mutationFn: async ({ role }: { role: Role }) => {
      const response = await api.post<Role>(`/perms/roles/`, role)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['roles'] })
      toast.success(t('CreateRoleScreen.createdMessage', 'Role created successfully.'))
      navigate('/roles')
    },
    onError: () => {
      toast.error(t('CreateRoleScreen.createError', "Couldn't create Role."))
    },
  })

  return (
    <RightPanel>
      <PanelHeader
        returnsTo="/roles"
        title={t('CreateRoleScreen.title', 'Create a new Role')}
        subtitle={t('CreateRoleScreen.subtitle', 'Enroll a new Role in the system.')}
      />
      <RoleForm
        submitType="create"
        initialValues={
          {
            name: '',
            description: '',
            canRefund: false,
            canReprint: false,
            canViewAny: false,
            canRefundAny: false,
            canReprintAny: false,
            isActive: true,
          } as Omit<Role, 'id'>
        }
        onSubmit={async (values, actions) => {
          await addRole({ role: values as Role }, { onSuccess: () => actions.resetForm() })
        }}
        loading={loadingAddUser}
      />
    </RightPanel>
  )
}
