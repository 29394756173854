import React, { useState } from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import moment from 'moment'

import { Table } from '~components'
import { Role } from '~types'

type RolesTableProps = {
  roles: Role[]
}

export const RolesTable: React.FC<RolesTableProps> = ({ roles }) => {
  const { t } = useTranslation()
  const [sorting, setSorting] = useState<SortingState>([])

  const columnHelper = createColumnHelper<Role>()

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      cell: (info) => (
        <Link to={`/roles/${info.row.original.id}/edit`}>
          <i>{info.getValue()}</i>
        </Link>
      ),
      header: () => t('Fields.name', 'Name'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('description', {
      header: () => t('Fields.description', 'Description'),
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('canRefund', {
      header: () => t('Fields.canRefund', 'Can Refund'),
      footer: (info) => info.column.id,
      cell: (info) =>
        info.getValue() ? (
          <CheckCircleIcon className="w-4 h-4 text-green-700" />
        ) : (
          <XCircleIcon className="w-4 h-4 text-red-700" />
        ),
    }),
    columnHelper.accessor('canReprint', {
      header: () => t('Fields.canReprint', 'Can Reprint'),
      footer: (info) => info.column.id,
      cell: (info) =>
        info.getValue() ? (
          <CheckCircleIcon className="w-4 h-4 text-green-700" />
        ) : (
          <XCircleIcon className="w-4 h-4 text-red-700" />
        ),
    }),
    columnHelper.accessor('canViewAny', {
      header: () => t('Fields.canViewAny', 'Can View Any'),
      footer: (info) => info.column.id,
      cell: (info) =>
        info.getValue() ? (
          <CheckCircleIcon className="w-4 h-4 text-green-700" />
        ) : (
          <XCircleIcon className="w-4 h-4 text-red-700" />
        ),
    }),
    columnHelper.accessor('canReprintAny', {
      header: () => t('Fields.canReprintAny', 'Can Reprint Any'),
      footer: (info) => info.column.id,
      cell: (info) =>
        info.getValue() ? (
          <CheckCircleIcon className="w-4 h-4 text-green-700" />
        ) : (
          <XCircleIcon className="w-4 h-4 text-red-700" />
        ),
    }),
    columnHelper.accessor('canRefundAny', {
      header: () => t('Fields.canRefundAny', 'Can Refund Any'),
      footer: (info) => info.column.id,
      cell: (info) =>
        info.getValue() ? (
          <CheckCircleIcon className="w-4 h-4 text-green-700" />
        ) : (
          <XCircleIcon className="w-4 h-4 text-red-700" />
        ),
    }),
    columnHelper.accessor('createdAt', {
      header: () => t('Fields.createdAt', 'Created'),
      footer: (info) => info.column.id,
      cell: (info) => moment(info.getValue()).format('DD-MM-YYYY HH:mm'),
    }),
    columnHelper.accessor('updatedAt', {
      header: () => t('Fields.updatedAt', 'Updated'),
      footer: (info) => info.column.id,
      cell: (info) => moment(info.getValue()).format('DD-MM-YYYY HH:mm'),
    }),
  ]
  const table = useReactTable<Role>({
    data: roles,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  })

  return <Table table={table} key="roles" />
}
