import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Button, InputField } from '~components'
import { Role } from '~types'

type RoleFormProps = {
  initialValues: Role | Omit<Role, 'id'>
  submitType: 'create' | 'edit'
  onSubmit: (
    values: Role | Omit<Role, 'id'>,
    actions: FormikHelpers<Role | Omit<Role, 'id'>>,
  ) => void
  loading?: boolean
}

export const RoleForm: React.FC<RoleFormProps> = ({
  initialValues,
  submitType,
  onSubmit,
  loading,
}: RoleFormProps) => {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(100).required(t('ValidationMessages.required', 'Is required')),
      })}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ values, dirty }) => {
        return (
          <Form className="flex flex-col">
            <InputField
              name="isActive"
              type="switch"
              label={
                values.isActive ? t('Fields.active', 'Active') : t('Fields.inactive', 'Inactive')
              }
              className="pb-2 self-end text-lg"
              invertedLabel
            />
            <InputField
              name="name"
              disabled={submitType === 'edit'}
              type="text"
              label={t('Fields.name', 'Name')}
              className="pb-2"
            />
            <InputField
              name="description"
              type="text"
              label={t('Fields.description', 'Description')}
              className="pb-2"
            />
            <InputField
              name="canRefund"
              type="checkbox"
              label={t('Fields.canRefund', 'Can Refund')}
              className="pb-2"
            />
            <InputField
              name="canReprint"
              type="checkbox"
              label={t('Fields.canReprint', 'Can Reprint')}
              className="pb-2"
            />
            <InputField
              name="canViewAny"
              type="checkbox"
              label={t('Fields.canViewAny', 'Can View Any')}
              className="pb-2"
            />
            <InputField
              name="canRefundAny"
              type="checkbox"
              label={t('Fields.canRefundAny', 'Can Refund Any')}
              className="pb-2"
            />
            <InputField
              name="canReprintAny"
              type="checkbox"
              label={t('Fields.canReprintAny', 'Can Reprint Any')}
              className="pb-2"
            />
            <Button
              type="submit"
              className="py-2 font-semibold text-lg"
              loading={loading}
              disabled={!dirty}>
              {submitType === 'edit'
                ? t('General.update', 'Update')
                : t('General.create', 'Create')}{' '}
              {t('General.role', 'Role')}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
