import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompass, faSyncAlt, faTire, faYinYang } from '@fortawesome/pro-regular-svg-icons'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'

interface SpinnerProps {
  color?: string
  size?: SizeProp
}

export const Spinner: React.FC<SpinnerProps> = ({ size, color }) => {
  const spinnerSize = size || 'lg'
  const spinnerColor = color || 'white'
  const spinners = [faTire, faYinYang, faSyncAlt, faCompass]
  const randomSpinner = spinners[Math.floor(Math.random() * spinners.length)]
  return <FontAwesomeIcon icon={randomSpinner} spin size={spinnerSize} color={spinnerColor} />
}
