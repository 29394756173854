import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'

import useApi from '~api/api'
import { Category, TicketType } from '~types'

export const PosPreview: React.FC = () => {
  const api = useApi()

  const ticketsAndCategories = useQuery({
    queryKey: ['categories_and_tickets'],
    queryFn: async () => {
      const response = await api.get<Category[]>('tickets/categories/', {
        as_device: true,
        with_tickets: true,
        with_favorite: true,
      })
      return response.data
    },
  })

  if (!ticketsAndCategories || !ticketsAndCategories.data || ticketsAndCategories.isLoading) {
    return <div className="ml-2 border rounded-md w-full">Loading...</div>
  }

  if (ticketsAndCategories.error) {
    return (
      <div className="ml-2 border rounded-md w-full">
        Error: {ticketsAndCategories.error.message}
      </div>
    )
  }

  return (
    <div className="ml-2 border rounded-md w-full h-auto max-w-144 overflow-y-auto">
      {ticketsAndCategories.data.map((category) => (
        <div key={category.id} className="flex flex-col bg-stone-300">
          <div className="flex flex-col items-start p-4 bg-white drop-shadow-md">
            <div
              className="font-bold text-lg"
              dangerouslySetInnerHTML={{ __html: category.name.replace('\\n', '<br/>') }}></div>
            <div className="text-wrap">{category.description}</div>
          </div>
          <div className="flex flex-row flex-wrap p-2 gap-2">
            {category.ticketTypes &&
              category.ticketTypes.map((ticket: TicketType) => (
                <div
                  key={ticket.uid}
                  className={clsx(
                    'flex flex-col bg-white rounded-md drop-shadow-md w-40 justify-between',
                    ticket.minQuantity > 1 && 'bg-yellow-100',
                    ticket.bundledTickets.length > 0 && 'bg-cyan-100',
                  )}>
                  <div className="flex flex-col p-4">
                    <div
                      className="font-bold text-lg"
                      dangerouslySetInnerHTML={{
                        __html: ticket.name.replace('\\n', '<br/>'),
                      }}></div>
                    <div
                      className="text-wrap"
                      dangerouslySetInnerHTML={{
                        __html: ticket.category.name.replace('\\n', '<br/>'),
                      }}></div>
                    <div className="text-wrap text-right font-bold text-lg">{ticket.pricePer}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  )
}
