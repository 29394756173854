import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { FormDisplay, PanelHeader, RightPanel, RoleForm } from '~components'
import { useRenderError } from '~hooks'
import { Role } from '~types'
import useApi from '~api/api'

export const RoleEditPage = () => {
  const { t } = useTranslation()
  const api = useApi()
  const queryClient = useQueryClient()
  const { roleId } = useParams<{ roleId: string }>()
  if (!roleId) {
    throw Error('You must provide a roleId using the url.')
  }
  const navigate = useNavigate()

  const { mutateAsync: editRole, isPending: updatingRole } = useMutation({
    mutationFn: async ({ role }: { role: Role }) => {
      const response = await api.put<Role>(`/perms/roles/${role.id}/`, role)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['roles'] })
      toast.success(t('EditRoleScreen.updatedMesage', 'Role updated successfully.'))
      navigate('/roles')
    },
    onError: () => {
      toast.error(t('EditRoleScreen.updateError', "Couldn't update Role."))
    },
  })

  const {
    data: initialValues,
    isLoading: loadingRole,
    error: fetchRoleError,
  } = useQuery<Role>({
    queryKey: ['role', roleId],
    queryFn: async () => {
      const response = await api.get<Role>(`/perms/roles/${roleId}/`)
      return response.data
    },
  })

  const fetchError = useRenderError({
    error: fetchRoleError,
    defaultMessage: t('EditRoleScreen.fetchError', "Couldn't get Role."),
  })

  return (
    <RightPanel>
      <PanelHeader
        returnsTo="/roles"
        title={t('EditRoleScreen.title', 'Edit Role.')}
        subtitle={
          <p>
            <Trans
              i18nKey={`EditRoleScreen.subtitle`}
              values={{
                resource: initialValues?.name,
              }}
              components={{
                strong: <strong />,
              }}
            />
          </p>
        }
      />
      <FormDisplay isLoading={loadingRole} error={fetchError}>
        {initialValues && (
          <RoleForm
            submitType="edit"
            initialValues={initialValues}
            onSubmit={async (values) => {
              await editRole({ role: values as Role })
            }}
            loading={updatingRole}
          />
        )}
      </FormDisplay>
    </RightPanel>
  )
}
