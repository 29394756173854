import { Fragment, useMemo } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import logo from './logo.png'

import { useAuth } from '~hooks'
import { LanguageSelect } from '~components'

export const Header = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()
  return useMemo(
    () => (
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-full px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">{t('Header.openMainMenu', 'Open main menu')}</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <Link to="/">
                      <img className="h-10 w-auto" src={logo} alt="Hop-On Ticketing" />
                    </Link>
                  </div>
                  {auth.loggedIn && (
                    <>
                      <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                        <Link
                          to="/"
                          className={clsx(
                            'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900',
                            location.pathname === '/' && 'border-b-2 border-teal-500 ',
                          )}>
                          {t('Header.dashboardLink', 'Dashboard')}
                        </Link>
                      </div>
                      <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                        <Link
                          to="/ticket-categories"
                          className={clsx(
                            'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900',
                            location.pathname === '/ticket-categories' &&
                              'border-b-2 border-teal-500 ',
                          )}>
                          {t('Header.categoriesLink', 'Ticket Categoriess')}
                        </Link>
                      </div>
                      <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                        <Link
                          to="/users"
                          className={clsx(
                            'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900',
                            location.pathname === '/users' && 'border-b-2 border-teal-500 ',
                          )}>
                          {t('Header.usersLink', 'Users')}
                        </Link>
                      </div>
                      <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                        <Link
                          to="/roles"
                          className={clsx(
                            'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900',
                            location.pathname === '/roles' && 'border-b-2 border-teal-500 ',
                          )}>
                          {t('Header.rolesLink', 'Roles')}
                        </Link>
                      </div>
                      <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                        <Link
                          to="/settings"
                          className={clsx(
                            'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900',
                            location.pathname === '/settings' && 'border-b-2 border-teal-500 ',
                          )}>
                          {t('Header.settingsLink', 'Settings')}
                        </Link>
                      </div>
                      {/* <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {auth.loggedIn && (
                      <Link
                        to="/vouchers"
                        className="inline-flex items-center border-b-2 border-teal-500 px-1 pt-1 text-sm font-medium text-gray-900">
                        {t('Header.vouchersLink', 'Vouchers')}
                      </Link>
                    )}
                  </div> */}
                      {/* <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {auth.loggedIn && (
                      <Link
                        to="/sales"
                        className="inline-flex items-center border-b-2 border-teal-500 px-1 pt-1 text-sm font-medium text-gray-900">
                        {t('Header.salesLink', 'Sales')}
                      </Link>
                    )}
                  </div> */}
                    </>
                  )}
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <LanguageSelect />
                  {/* Profile dropdown */}
                  {auth.loggedIn && (
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none capitalize">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">
                            {t('Header.openUserMenu', 'Open user menu')}
                          </span>
                          {t('Header.hi', 'Hi')} {auth.firstName ?? ''}{' '}
                          <ChevronDownIcon className="ml-1 pt-1" color="#999" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {/* <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/profile"
                                className={clsx(
                                  active ? 'bg-teal-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700',
                                )}>
                                {t('Header.profileLink', 'Your Profile')}
                              </Link>
                            )}
                          </Menu.Item> */}
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={auth.logout}
                                className={clsx(
                                  active ? 'bg-teal-100' : '',
                                  'w-full block px-4 py-2 text-left text-sm text-gray-700',
                                )}>
                                {t('Header.signOut', 'Sign out')}
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 pb-4 pt-2">
                {auth.loggedIn && (
                  <Disclosure.Button
                    as="button"
                    onClick={() => navigate('/')}
                    className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700">
                    Dashboard
                  </Disclosure.Button>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    ),
    [auth, navigate],
  )
}
