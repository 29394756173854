import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'preline/preline'
import { PostHogProvider, usePostHog } from 'posthog-js/react'
import * as Sentry from '@sentry/react'
import { PostHogConfig } from 'posthog-js'

import { Main } from './routing'
import { AuthProvider } from './hooks'
import './languages'

const App = () => {
  const posthog = usePostHog()
  if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
    Sentry.init({
      dsn: 'https://4381017b840880afb84d87de21850a8c@o509489.ingest.us.sentry.io/4508309140668416',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        posthog.sentryIntegration({
          organization: 'sagitar',
          projectId: 4508309140668416,
          severityAllowList: ['error', 'info'], // optional: here is set to handle captureMessage (info) and captureException (error)
        }),
      ],
      environment: process.env.REACT_APP_ENVIRONMENT,
      // Session Replay
      replaysSessionSampleRate: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    })
  }

  const queryClient = new QueryClient()

  const posthogOptions: Partial<PostHogConfig> = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://eu.i.posthog.com',
    person_profiles: 'identified_only',
    capture_pageview: false,
    debug: process.env.REACT_APP_ENVIRONMENT === 'dev',
    disable_session_recording: process.env.REACT_APP_ENVIRONMENT === 'dev',
  }

  return (
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={posthogOptions}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AuthProvider>
          <ToastContainer />
          <Main />
        </AuthProvider>
      </QueryClientProvider>
    </PostHogProvider>
  )
}

export default App
