import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/pro-regular-svg-icons'
import { DndContext, UniqueIdentifier } from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { toast } from 'react-toastify'
import { findIndex } from 'lodash'
import { Bars3Icon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import { Button, HeaderLayout, NoData, PosPreview } from '~components'
import { BundleType, TicketCategory, TicketType } from '~types'
import useApi from '~api/api'

const TicketCard: React.FC<{
  ticket: TicketType
}> = ({ ticket }) => {
  const { t } = useTranslation()
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: ticket.uid,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <li
      key={ticket.uid}
      className="flex bg-white items-center justify-between gap-x-6 mb-2 mx-1 p-5 border border-gray-200 rounded-md"
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}>
      <div className="min-w-256">
        <div className="flex items-start gap-x-3">
          <div className="flex flex-col w-2/5">
            <div className="font-bold text-lg text-teal-700 hover:text-teal-600">
              <Link
                to={`/ticket-categories/${ticket.category.id}/tickets/${ticket.uid}/edit`}
                dangerouslySetInnerHTML={{ __html: ticket.name.replace('\\n', '<br/>') }}></Link>
            </div>
            <div className="text-wrap">{ticket.description}</div>
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.pricePer', 'Price per')}</div>
            <div>{ticket.pricePer}</div>
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.minQuantityShort', 'Min. Qty')}</div>
            <div>{ticket.minQuantity}</div>
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.vatRate', 'VAT Rate')}</div>
            <div>{ticket.vatRate.rate}</div>
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.isFavorite', 'Is favorite')}</div>
            {ticket.isFavorite ? (
              <CheckCircleIcon className="w-6 h-6 text-green-700" />
            ) : (
              <XCircleIcon className="w-6 h-6 text-red-700" />
            )}
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.active')}</div>
            {ticket.isActive ? (
              <CheckCircleIcon className="w-6 h-6 text-green-700" />
            ) : (
              <XCircleIcon className="w-6 h-6 text-red-700" />
            )}
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.created')}</div>
            <div>{new Date(ticket.createdAt).toLocaleDateString()}</div>
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.updated')}</div>
            <div>{new Date(ticket.updatedAt).toLocaleDateString()}</div>
          </div>
        </div>
      </div>
      <Bars3Icon className="w-10 h-10 text-gray-300" />
    </li>
  )
}

const BundleCard: React.FC<{
  ticket: BundleType
}> = ({ ticket }) => {
  const { t } = useTranslation()

  return (
    <li
      key={ticket.uid}
      className="flex bg-white items-center justify-between gap-x-6 mb-2 mx-1 p-5 border border-gray-200 rounded-md">
      <div className="min-w-256">
        <div className="flex items-start gap-x-3">
          <div className="flex flex-col w-2/5">
            <div className="font-bold text-lg text-teal-700 hover:text-teal-600">
              <Link
                to={`/ticket-categories/${ticket.category.id}/tickets/${ticket.uid}/edit`}
                state={{ ticketType: 'bundle' }}
                dangerouslySetInnerHTML={{
                  __html: ticket.name.replace('\\n', '<br/>'),
                }}></Link>
            </div>
            <div className="text-wrap">{ticket.description}</div>
          </div>
          <div className="flex flex-col items-center w-1/5"></div>
          <div className="flex flex-col items-center w-1/5"></div>
          <div className="flex flex-col items-center w-1/5"></div>
          <div className="flex flex-col items-center w-1/5"></div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.active')}</div>
            {ticket.isActive ? (
              <CheckCircleIcon className="w-6 h-6 text-green-700" />
            ) : (
              <XCircleIcon className="w-6 h-6 text-red-700" />
            )}
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.created')}</div>
            <div>{new Date(ticket.createdAt).toLocaleDateString()}</div>
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.updated')}</div>
            <div>{new Date(ticket.updatedAt).toLocaleDateString()}</div>
          </div>
        </div>
      </div>
      <Bars3Icon className="w-10 h-10 text-white" />
    </li>
  )
}

export const TicketTypesListPage = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const api = useApi()
  const { state } = useLocation()
  const [selectedTab, setSelectedTab] = React.useState((state && state.tab) || 'tickets')
  const { categoryId } = useParams<{ categoryId: string }>()

  const {
    data: category,
    isLoading: loadingCategory,
    error: categoryError,
  } = useQuery<TicketCategory>({
    queryKey: ['categories', categoryId],
    queryFn: async () => {
      const response = await api.get<TicketCategory>(`/tickets/categories/${categoryId}/`)
      return response.data
    },
  })

  const {
    data: ticketTypes,
    isLoading: loadingListTicketTypes,
    error: listTicketTypesError,
  } = useQuery<TicketType[]>({
    queryKey: ['categories', categoryId, 'tickets'],
    queryFn: async () => {
      const response = await api.get<TicketType[]>(`/tickets/categories/${categoryId}/tickets/`)
      return response.data
    },
    enabled: categoryId !== undefined && !loadingCategory,
  })

  const {
    data: bundleTypes,
    isLoading: loadingListBundleTypes,
    error: listBundleTypesError,
  } = useQuery<BundleType[]>({
    queryKey: ['categories', categoryId, 'bundles'],
    queryFn: async () => {
      const response = await api.get<BundleType[]>(`/tickets/categories/${categoryId}/bundles/`)
      return response.data
    },
    enabled: categoryId !== undefined && !loadingCategory,
  })

  const swapTickets = useMutation({
    mutationFn: async ({ toSwap }: { toSwap: number[] }) => {
      console.log('toSwap', toSwap)
      const tickets = arrayMove(ticketTypes || [], toSwap[0], toSwap[1])
      await api.put<TicketType>(
        '/tickets/',
        tickets.map((ticket) => ticket.uid),
      )
    },
    onMutate: async ({ toSwap }) => {
      console.log('toSwap', toSwap)
      queryClient.setQueryData(['categories', categoryId, 'tickets'], (oldData: TicketType[]) => {
        return arrayMove(oldData, toSwap[0], toSwap[1])
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories', categoryId, 'tickets'] })
      queryClient.invalidateQueries({ queryKey: ['categories_and_tickets'] })
    },
    onError: () => toast.error(t('EditTicketScreen.updateError', "Couldn't update Ticket order.")),
  })

  if (
    !ticketTypes ||
    loadingListTicketTypes ||
    !category ||
    loadingCategory ||
    !bundleTypes ||
    loadingListBundleTypes
  ) {
    return null
  }

  if (listTicketTypesError || categoryError || listBundleTypesError) {
    toast.error(t('EditCategoryScreen.fetchError', "Couldn't get Category Tickets."))
  }

  const getIndex = (id: UniqueIdentifier) => findIndex(ticketTypes, (ticket) => ticket.uid === id)

  const tabs = [
    { id: 'tickets', name: t('General.tickets', 'Tickets') },
    { id: 'bundles', name: t('General.bundleableTickets', 'Bundleable Tickets') },
  ]

  return (
    <HeaderLayout>
      <div className="relative h-full">
        <div className="flex flex-row px-2">
          <div className="flex flex-col p-8 h-screen w-full">
            <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
              <div className="md:flex md:items-center md:justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  <Link to="/ticket-categories">
                    <FontAwesomeIcon
                      icon={faChevronCircleLeft}
                      size="xl"
                      className="text-teal-500 mr-4"
                    />
                  </Link>
                  {t('Fields.category', 'Category')}:{' '}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: category.name.replace('\\n', '') || 'Loading...',
                    }}></span>
                </h3>
                <div className="mt-3 flex md:mt-0 md:absolute md:top-3 md:right-0">
                  <Link
                    to={`/ticket-categories/${categoryId}/tickets/add`}
                    state={{ isBundledOnly: true }}
                    className="inline-flex items-center text-sm font-medium text-gray-900">
                    <Button className="py-2 px-1" style="secondary">
                      {t('ListTicketsScreen.newBundleTicket', 'New Bundle Ticket')}
                    </Button>
                  </Link>
                  <Link
                    to={`/ticket-categories/${categoryId}/tickets/add`}
                    className="inline-flex items-center text-sm font-medium text-gray-900">
                    <Button className="py-2 px-1">
                      {t('ListTicketsScreen.newTicket', 'New Ticket')}
                    </Button>
                  </Link>
                </div>
              </div>
              <div className="mt-4">
                <div className="sm:hidden">
                  <label htmlFor="current-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="current-tab"
                    name="current-tab"
                    onChange={(e) => setSelectedTab(e.target.value)}
                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm rounded-md"
                    defaultValue={selectedTab}>
                    {tabs.map((tab) => (
                      <option key={tab.id} value={tab.id}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                      <button
                        key={tab.id}
                        onClick={() => setSelectedTab(tab.id)}
                        className={clsx(
                          tab.id === selectedTab
                            ? 'border-teal-500 text-teal-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                          'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
                        )}
                        aria-current={tab.id === selectedTab ? 'page' : undefined}>
                        {tab.name}
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
            <div className="flex-1 mt-4 mb-20 overflow-y-auto">
              {ticketTypes.length === 0 ? (
                <NoData show />
              ) : selectedTab === 'tickets' ? (
                ticketTypes.length > 0 ? (
                  <div className="space-y-4">
                    <DndContext
                      onDragEnd={({ active, over }) => {
                        if (over && active.id !== over.id) {
                          console.log('active', active)
                          console.log('over', over)
                          swapTickets.mutateAsync({
                            toSwap: [getIndex(active.id), getIndex(over.id)],
                          })
                        }
                      }}>
                      <SortableContext
                        items={ticketTypes.map((row) => row.uid)}
                        strategy={verticalListSortingStrategy}>
                        <ul role="list" className="bg-slate-50">
                          {ticketTypes?.map((ticket) => (
                            <TicketCard key={ticket.uid} ticket={ticket} />
                          ))}
                        </ul>
                      </SortableContext>
                    </DndContext>
                  </div>
                ) : (
                  <NoData show />
                )
              ) : bundleTypes.length > 0 ? (
                <ul role="list" className="bg-slate-50">
                  {bundleTypes?.map((ticket) => <BundleCard key={ticket.uid} ticket={ticket} />)}
                </ul>
              ) : (
                <div className="flex flex-col items-center justify-center h-full w-full">
                  <NoData show />
                </div>
              )}
            </div>
          </div>
          <PosPreview />
        </div>
        <div className="fixed right-0 top-0 h-full">
          <Outlet />
        </div>
      </div>
    </HeaderLayout>
  )
}
