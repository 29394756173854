import { Link, Outlet } from 'react-router-dom'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { DndContext } from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { toast } from 'react-toastify'
import { findIndex } from 'lodash'
import { Bars3Icon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'

import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../components/ui/hover-card'

import { Button, HeaderLayout, PosPreview } from '~components'
import { TicketCategory } from '~types'
import useApi from '~api/api'

const CategoryCard: React.FC<{
  category: TicketCategory
}> = ({ category }) => {
  const { t } = useTranslation()
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: category.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <li
      className="flex bg-white items-center justify-between gap-x-6 mb-2 mx-1 p-5 border border-gray-200 rounded-md"
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}>
      <div className="min-w-256">
        <div className="flex items-start gap-x-3">
          <div className="flex flex-col w-2/5">
            <div className="font-bold text-lg text-teal-700 hover:text-teal-600">
              <Link
                to={`/ticket-categories/${category.id}/edit`}
                dangerouslySetInnerHTML={{ __html: category.name.replace('\\n', '<br/>') }}></Link>
            </div>
            <div className="text-wrap">{category.description}</div>
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('General.tickets')}</div>
            <div>
              <HoverCard>
                <HoverCardTrigger>
                  {category.activeTicketTypeCount} / {category.ticketTypeCount}
                </HoverCardTrigger>
                <HoverCardContent className="text-xs p-1 w-32 text-center text-gray-500">
                  ({t('Fields.active')}) / ({t('Fields.inactive')})
                </HoverCardContent>
              </HoverCard>
            </div>
            <Link to={`/ticket-categories/${category.id}/tickets`}>
              <div className="text-sm text-teal-600 hover:text-teal-400">
                {t('ListCategoriesScreen.viewTickets', 'View Tickets')}
              </div>
            </Link>
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.active')}</div>
            {category.isActive ? (
              <CheckCircleIcon className="w-6 h-6 text-green-700" />
            ) : (
              <XCircleIcon className="w-6 h-6 text-red-700" />
            )}
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.visible')}</div>
            {category.isVisible ? (
              <CheckCircleIcon className="w-6 h-6 text-green-700" />
            ) : (
              <XCircleIcon className="w-6 h-6 text-red-700" />
            )}
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.created')}</div>
            <div>{new Date(category.createdAt).toLocaleDateString()}</div>
          </div>
          <div className="flex flex-col items-center w-1/5">
            <div className="font-bold">{t('Fields.updated')}</div>
            <div>{new Date(category.updatedAt).toLocaleDateString()}</div>
          </div>
        </div>
      </div>
      <Bars3Icon className="w-10 h-10 text-gray-300" />
    </li>
  )
}

export const TicketCategoriesListPage = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const api = useApi()

  const {
    data: ticketCategories,
    isLoading: loadingListTicketCategories,
    error: listTicketCategoriesError,
  } = useQuery<TicketCategory[]>({
    queryKey: ['categories'],
    queryFn: async () => {
      const response = await api.get<TicketCategory[]>('/tickets/categories/')
      return response.data
    },
  })

  const swapCategories = useMutation({
    mutationFn: async ({ catsToSwap }: { catsToSwap: number[] }) => {
      const categories = arrayMove(ticketCategories || [], catsToSwap[0], catsToSwap[1])
      await api.put<TicketCategory>(
        '/tickets/categories/',
        categories.map((category) => category.id),
      )
    },
    onMutate: async ({ catsToSwap }) => {
      queryClient.setQueryData(['categories'], (oldData: TicketCategory[]) => {
        return arrayMove(oldData, catsToSwap[0], catsToSwap[1])
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] })
      queryClient.invalidateQueries({ queryKey: ['categories_and_tickets'] })
    },
    onError: () => {
      toast.error(t('EditCategoryScreen.updateError', "Couldn't update Category order."))
    },
  })

  if (!ticketCategories || loadingListTicketCategories) {
    return null
  }

  if (listTicketCategoriesError) {
    return <div>Error: {listTicketCategoriesError.message}</div>
  }

  const getIndex = (id: string | number) =>
    findIndex(ticketCategories, (category) => category.id === id)

  return (
    <HeaderLayout>
      <div className="relative h-full">
        <div className="flex flex-col p-8 h-full">
          <div>
            <Link
              to="/ticket-categories/add"
              className="inline-flex items-center text-sm font-medium text-gray-900 py-4">
              <Button>{t('ListCategoriesScreen.newCategory', 'New Ticket Category.')}</Button>
            </Link>
          </div>
          <div className="flex flex-row">
            <DndContext
              onDragEnd={({ active, over }) => {
                if (over && active.id !== over.id) {
                  swapCategories.mutateAsync({
                    catsToSwap: [getIndex(active.id), getIndex(over.id)],
                  })
                }
              }}>
              <SortableContext
                items={ticketCategories.map((row) => row.id)}
                strategy={verticalListSortingStrategy}>
                <ul role="list" className="bg-slate-50">
                  {ticketCategories?.map((category) => (
                    <CategoryCard key={category.id} category={category} />
                  ))}
                </ul>
              </SortableContext>
            </DndContext>
            <PosPreview />
          </div>
        </div>
        <div className="fixed right-0 top-0 h-full">
          <Outlet />
        </div>
      </div>
    </HeaderLayout>
  )
}
